import React, { useState } from "react";
import PropTypes, { number } from "prop-types";
import _ from "lodash";
import ahoy from "ahoy.js";
import PrecacheImg from "../../../../utils/PrecacheImage";
import Modal from "../../../../utils/Modal";
import LightBox from "./LightBox";
import Favorite from "./Favorite";
import CollectCustomerEmailModal from "../CollectCustomerEmailModal";
import Gallery from "react-grid-gallery";
import millisecondsTohms from "../../../../utils/millisecondsTohms";
import Telemetry from "../../../../utils/rudderstackAnalytics";
const gssNewLoader = require("../../../../assets/gss-loader.gif");
const playIcon = require("../../styles/play.svg");
const hiddenIcon = require("../../styles/imgHide.svg");
import { Album as AlbumType, Event } from "../types";
import { CartOutline, CartFilled } from "../vectors/AddToCartIcon";
import { CheckOutline, CheckFilled } from "../vectors/CheckIcon";
import { Cart } from "../../components/types";

interface ImageComponentProps {
  props: {
    item: {
      videoContent: any;
      videoDuration: string | number;
      thumbnail: string | undefined;
      id: number;
      album: { name: string };
      hidden: boolean;
      isFavorite: boolean;
      isSelected: boolean;
    };
    imageProps: React.ImgHTMLAttributes<HTMLImageElement>;
    index: number;
  };
  handleImageSelect: ({ photo, index }: { photo: any; index: number }) => void;
  primaryColor: string;
  shouldShowCheckIcon: boolean;
  event: { id: number; name: string };
}

const ImageComponent = ({
  props,
  handleImageSelect,
  primaryColor,
  shouldShowCheckIcon,
  event
}: ImageComponentProps) => {
  const [show, setShow] = useState(false);

  const ImageSelectIcon = (
    <div
      className="geo-cart-icon-container"
      onClick={e => {
        e.stopPropagation();
        handleImageSelect({ photo: props.item, index: props.index });
      }}
    >
      {shouldShowCheckIcon ? (
        props.item.isSelected ? (
          <CheckFilled color={primaryColor || "#34b2a7"} />
        ) : (
          <CheckOutline />
        )
      ) : props.item.isSelected ? (
        <CartFilled color={primaryColor || "#34b2a7"} />
      ) : (
        <CartOutline />
      )}
    </div>
  );

  if (props.item.videoContent) {
    return (
      <div style={{ textAlign: "center", cursor: "pointer" }}>
        {props.item.hidden && (
          <span className="visibility-stamp">
            <img src={hiddenIcon} alt="" />
          </span>
        )}
        {ImageSelectIcon}
        <span className="time-stamp">
          {millisecondsTohms(props.item.videoDuration)}
        </span>
        <img className="play-icon" src={playIcon} alt="" />
        <img
          height="120px"
          src={props.item.thumbnail}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          alt={`${props.item.album.name}-${props.item.id}`}
        />
      </div>
    );
  } else
    return (
      <div style={{ textAlign: "center", cursor: "pointer" }}>
        {props.item.hidden && (
          <span className="visibility-stamp">
            <img src={hiddenIcon} alt="" />
          </span>
        )}
        {ImageSelectIcon}
        <img
          {...props.imageProps}
          alt={`${props.item.album.name}-${props.item.id}`}
        />
      </div>
    );
};

interface PhotosProps {
  event: Event;
  album: AlbumType;
  photos: any;
  cart?: {
    photos: any;
    id: number;
  };
  addPhotoToCart: Function;
  removePhotoFromCart: Function;
  loadMorePhotos: Function;
  updateAlbum: Function;
  loadingPhotos: boolean;
  expanded: boolean;
  collapsibleGallery: boolean;
  galleryRowsRendered: Function;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  curationMode: boolean;
  userId: number;
  userSignedIn: boolean;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  setGuestUserCart: (userId: number, userEmail: string, cart: Cart) => void;
  guestUserId: null | number;
  filteredResult: boolean;
}

interface PhotosState {
  photos: any;
  showLightBox: boolean;
  photoIndex: number;
  reload: boolean;
  curationApiCalled: boolean;
  showEmailCollectModal: boolean;
  favorites: number[];
  selectedPhoto: { id: number | null; isFavorite: boolean; source: string };
}

class Photos extends React.Component<PhotosProps, PhotosState> {
  constructor(props: PhotosProps) {
    super(props);

    this.state = {
      photos: [],
      showLightBox: false,
      photoIndex: -1,
      reload: false,
      curationApiCalled: false,
      showEmailCollectModal: false,
      favorites: [],
      selectedPhoto: { id: null, isFavorite: false, source: "" }
    };
  }

  static contextTypes = {
    curationMode: PropTypes.bool,
    loadAlbumPhotos: PropTypes.func,
    canManageTags: PropTypes.bool,
    eventCoverPickerMode: PropTypes.bool,
    selectCoverPhoto: PropTypes.func,
    getPhotoId: PropTypes.func,
    setPhotos: PropTypes.func,
    visiblePhotos: PropTypes.array,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    isEmbed: PropTypes.bool
  };

  buildPhotosForGallery = (photos: any[], isSelected: Function) => {
    this.setState(
      {
        photos: photos.map(photo => {
          return {
            ...photo,
            ...(photo.timeTaken
              ? {
                  tags: [
                    {
                      title: "Time",
                      value: photo.timeTaken
                    }
                  ]
                }
              : {}),
            isSelected: isSelected(photo),
            src: "",
            hidden: photo.hidden,
            disableContextMenu: true,
            isFavorite: photo.favorite
          };
        })
      },
      () => {
        this.context.canManageTags &&
          this.context.setPhotos(
            _.uniqBy(
              [...this.state.photos, ...this.context.visiblePhotos],
              "id"
            )
          );
      }
    );
  };

  componentDidMount() {
    if (this.props.filteredResult) {
      this.buildPhotosForGallery(this.props.photos, (photo: { id: any }) => {
        if (this.context.eventCoverPickerMode) {
          return photo.id === this.props.event.coverPhotoId;
        } else
          return _.includes(
            this.context.canManageTags && this.props.curationMode
              ? this.props.listOfMediaId.map(item => item.id)
              : this.props.cart && this.props.cart.photos,
            photo.id
          );
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.photos !== prevProps.photos ||
      this.props.loadingPhotos !== prevProps.loadingPhotos ||
      this.state.showLightBox !== prevState.showLightBox ||
      this.state.photoIndex !== prevState.photoIndex ||
      (this.props.cart != null && prevProps.cart == null) ||
      (this.props.cart &&
        prevProps.cart &&
        this.props.cart.photos.length !== prevProps.cart.photos.length) ||
      (!this.props.cart && prevProps.cart) ||
      this.props.collapsibleGallery !== prevProps.collapsibleGallery ||
      this.props.expanded !== prevProps.expanded ||
      this.props.photos.length !== prevProps.photos.length ||
      // @ts-ignore
      this.props.event.coverPhotoId !== prevProps.event.coverPhotoId ||
      (this.context.canManageTags &&
        (this.props.listOfMediaId.length !== prevProps.listOfMediaId.length ||
          this.props.curationMode !== prevProps.curationMode))
    ) {
      this.buildPhotosForGallery(this.props.photos, (photo: { id: any }) => {
        if (this.context.eventCoverPickerMode) {
          return photo.id === this.props.event.coverPhotoId;
        } else
          return _.includes(
            this.context.canManageTags && this.props.curationMode
              ? this.props.listOfMediaId.map(item => item.id)
              : this.props.cart && this.props.cart.photos,
            photo.id
          );
      });
      if (this.props.photos !== prevProps.photos && this.props.filteredResult) {
        this.setState({ showLightBox: false });
      }
    }
    if (this.props.guestUserId !== prevProps.guestUserId) {
      this.setState({
        showLightBox: false
      });
    }
  }

  showLightBox = (index: number) => {
    if (index == -1) {
      this.setState({ showLightBox: false });
      return;
    }

    this.setState({ showLightBox: false }, () => {
      this.setState({
        showLightBox: true,
        photoIndex: index
      });
    });
    ahoy.track("photoView", {
      photo_id: this.state.photos[index].id,
      album_id: this.props.album.id,
      event_id: this.props.event.id
    });

    if (this.state.photos[index + 1] == undefined) {
      this.props.loadMorePhotos();
    }
  };

  closeLightBox = () => {
    this.setState({
      showLightBox: false
    });
  };

  imagesToPrefetch = () => {
    if (this.props.photos.length > 0) {
      return [
        this.props.photos[0].watermarkedImg,
        this.props.photos[0].zoomImg
      ];
    } else {
      return [];
    }
  };

  contextMenu = (e: any) => {
    e.preventDefault();
    return false;
  };

  toggleEventCoverSelection = (photo: any) => {
    if (photo.isSelected) {
      this.context.selectCoverPhoto(null);
    } else this.context.selectCoverPhoto(photo.id);
  };

  updatePhotoInCart = (photo: any) => {
    if (photo.isSelected) {
      this.props.removePhotoFromCart(photo);
      Telemetry.track("event-gallery:remove-from-cart", {
        eventId: this.props.event.id,
        eventName: this.props.event.name,
        photoId: photo.id,
        cartId: this.context.cartId,
        source: "thumbnail",
        embeddedMode: this.context.isEmbed
      });
    } else {
      this.props.addPhotoToCart(photo);
      Telemetry.track("event-gallery:add-to-cart", {
        eventId: this.props.event.id,
        eventName: this.props.event.name,
        photoId: photo.id,
        cartId: this.props.cart?.id,
        source: "thumbnail",
        embeddedMode: this.context.isEmbed
      });
    }
  };

  // Function to toggle the showEmailCollectModal state
  handleEmailCollectModal = () => {
    this.setState(
      { showEmailCollectModal: !this.state.showEmailCollectModal },
      () => {
        if (this.state.showEmailCollectModal) {
          Telemetry.track("event-gallery:guest-favorites-email-prompted", {
            eventId: this.props.event.id,
            eventName: this.props.event.name,
            photoId: this.state.selectedPhoto.id,
            source: this.state.selectedPhoto.source
          });
        }
      }
    );
  };

  trackAddToFavorite = () => {
    Telemetry.track("event-gallery:add-to-favorites", {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      photoId: this.state.selectedPhoto.id,
      source: this.state.selectedPhoto.source
    });
  };

  addToFavorites = ({
    userId,
    userEmail,
    cart
  }: {
    userId: number;
    userEmail: string;
    cart: any;
  }) => {
    const requestBody = {
      user_id: userId,
      photo_id: this.state.selectedPhoto.id
    };
    fetch(this.props.addPhotoToFavoritesAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (!response.ok) {
          this.props.setGuestUserCart(userId, userEmail, cart);
          this.trackAddToFavorite();
          throw new Error("Already added to favorites");
        }
        return true;
      })
      .then(res => {
        this.props.setGuestUserCart(userId, userEmail, cart);
        this.trackAddToFavorite();
      })
      .catch(error => {
        const errorMessage = error.message.replace("Error: ", "");
        //@ts-ignore
        toastr.error(errorMessage);
      });
  };

  handleFavoritesSelection = ({ userId }: { userId: number }) => {
    const requestBody = {
      user_id: userId,
      photo_id: this.state.selectedPhoto.id
    };

    if (this.state.selectedPhoto.isFavorite) {
      fetch(this.props.removePhotoFromFavoritesAPI, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          this.props.updateAlbum(this.state.selectedPhoto.id);
        })
        .catch(error => {
          //@ts-ignore
          toastr.error(error.response.body.error);
        });
    } else {
      fetch(this.props.addPhotoToFavoritesAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          this.trackAddToFavorite();
          this.props.updateAlbum(this.state.selectedPhoto.id);
        })
        .catch(error => {
          //@ts-ignore
          toastr.error(error);
        });
    }
  };

  onClickFavoriteIcon = ({
    id,
    isFavorite,
    source
  }: {
    id: number;
    isFavorite: boolean;
    source: string;
  }) => {
    this.setState(
      { selectedPhoto: { id: id, isFavorite: isFavorite, source: source } },
      () => {
        if (this.props.userSignedIn) {
          this.handleFavoritesSelection({
            userId: this.props.userId
          });
        } else if (this.props.guestUserId) {
          this.handleFavoritesSelection({
            userId: this.props.guestUserId
          });
        } else {
          this.handleEmailCollectModal();
        }
      }
    );
  };

  handleImageSelect = ({ photo, index }: { photo: any; index: number }) => {
    if (this.context.eventCoverPickerMode) {
      this.toggleEventCoverSelection(photo);
    } else {
      this.context.canManageTags && this.props.curationMode
        ? this.context.getPhotoId(photo, index, this.state.photos)
        : this.updatePhotoInCart(photo);
    }
  };

  trackGuestFavoriteProvidedEmail = () => {
    Telemetry.track("event-gallery:guest-favorites-provided-email", {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      photoId: this.state.selectedPhoto.id,
      source: this.state.selectedPhoto.source
    });
  };

  render() {
    if (this.props.loadingPhotos) {
      return (
        <div className="gss-loader album-container ">
          <img src={gssNewLoader} alt="" />
        </div>
      );
    } else {
      return (
        <div
          className={`dv-album-photos ${
            this.props.collapsibleGallery
              ? this.props.expanded
                ? "expanded"
                : "collapsed"
              : ""
          }`}
        >
          <div className="dv-album-wrap">
            <Gallery
              images={this.state.photos}
              key={`${this.state.photos.length}-${
                this.state.photos[0]?.id || "default"
              }`}
              rowHeight={120}
              margin={5}
              enableLightbox={false}
              enableImageSelection={false}
              onClickThumbnail={(index: number) => {
                if (this.context.eventCoverPickerMode) {
                  this.toggleEventCoverSelection(this.state.photos[index]);
                } else {
                  this.showLightBox(index);
                  ahoy.track("albumInteraction", {
                    secondary_type: "openedPhotoLightbox",
                    album_id: this.props.album.id,
                    event_id: this.props.event.id
                  });
                }
                Telemetry.track("event-gallery:opened-lightbox", {
                  eventId: this.props.event.id,
                  eventName: this.props.event.name,
                  photoId: this.props.photos[index].id,
                  embeddedMode: this.context.isEmbed
                });
              }}
              tagStyle={tagStyle}
              thumbnailImageComponent={(props: any) => {
                return (
                  <ImageComponent
                    props={props}
                    handleImageSelect={this.handleImageSelect}
                    primaryColor={this.context.pageConfiguration.primaryColor}
                    shouldShowCheckIcon={
                      this.context.eventCoverPickerMode ||
                      (this.context.canManageTags && this.props.curationMode)
                    }
                    event={{
                      id: this.props.event.id,
                      name: this.props.event.name
                    }}
                  />
                );
              }}
              tileViewportStyle={customThumbViewportStyle}
              thumbnailStyle={customThumbStyle}
              selectedIconColor={
                this.context.pageConfiguration.primaryColor || "#34b2a7"
              }
              enableKeyboardInput={true}
              onContextMenu={this.contextMenu}
              //@ts-ignore
              selectCheckboxAlwaysVisible={Modernizr.mq(
                "only screen and (max-width: 768px)"
              )}
              onRender={this.props.galleryRowsRendered}
            />
            {this.state.showLightBox ? (
              <Modal>
                <LightBox
                  event={this.props.event}
                  album={this.props.album}
                  photos={this.state.photos}
                  photoIndex={this.state.photoIndex}
                  closeLightBox={this.closeLightBox}
                  showLightBox={this.showLightBox}
                  cartHasPhotos={
                    this.props.cart && this.props.cart.photos.length > 0
                  }
                  showFavIcon={true}
                  addToCart={this.props.addPhotoToCart}
                  removeFromCart={this.props.removePhotoFromCart}
                  eventClickable={undefined}
                  cartId={undefined}
                  isAuthorizedForDirectDownloads={undefined}
                  primaryColor={undefined}
                  userId={undefined}
                  isEmbed={undefined}
                  photoSaved={undefined}
                  curationApiCalled={this.state.curationApiCalled}
                  setCurationApiCalled={(val: boolean) =>
                    this.setState({ curationApiCalled: val })
                  }
                  onClickFavoriteIcon={this.onClickFavoriteIcon}
                  trackTelemetry={true}
                  parentName={"event-gallery-lightbox"}
                />
              </Modal>
            ) : null}
          </div>
          {this.state.showEmailCollectModal ? (
            <CollectCustomerEmailModal
              closeModal={this.handleEmailCollectModal}
              registerGuestUserAPI={this.props.registerGuestUserAPI}
              addToFavorites={this.addToFavorites}
              eventId={this.props.event.id}
              trackGuestFavoriteProvidedEmail={
                this.trackGuestFavoriteProvidedEmail
              }
            />
          ) : null}
          <PrecacheImg images={this.imagesToPrefetch()} />
        </div>
      );
    }
  }
}

const tagStyle = {
  display: "inline",
  padding: ".2em .6em .3em",
  fontSize: "60%",
  fontWeight: "600",
  lineHeight: "1",
  color: "#FFF",
  background: "rgba(7,36,22,.6)",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
  borderRadius: ".25em"
};

const customThumbViewportStyle = function (this: any) {
  return {
    width: this.props.item.vwidth,
    height: this.props.height,
    overflow: "hidden"
  };
};

const customThumbStyle = function (this: any) {
  return {
    cursor: "pointer",
    width: this.props.item.scaletwidth,
    height: this.props.height,
    marginLeft: this.props.item.marginLeft,
    marginTop: 0,
    WebkitTouchCallout: "none"
  };
};

export default Photos;
